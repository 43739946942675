import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <>
      <header id="site-header" className="fixed-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark stroke">
            <h1>
              <Link className="navbar-brand" to="/Home">
                <img
                  src="assets/images/logo.png"
                  style={{ height: "80px" }}
                ></img>
              </Link>
            </h1>
            {/* if logo is image enable this   
          <Link class="navbar-brand" to="#/Home">
              <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
          </Link> */}
            <button
              className="navbar-toggler  collapsed bg-gradient"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fa icon-expand fa-bars" />
              <span className="navbar-toggler-icon fa icon-close fa-times" />
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav ml-lg-auto">
                <li className="nav-item active">
                  <Link className="nav-link" to="/Home">
                    Home <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/About">
                    Who We Are
                  </Link>
                </li>
                <li class="nav-item dropdown">
                  <Link class="nav-link">What We Do</Link>
                  <div class="dropdown-content">
                   <Link to="/Ocean">Ocean Freight</Link>
                   <Link to="/Air">Air Freight</Link>
                   <Link to="/Ground">Ground Freight</Link>
                   <Link to="/Customs">Customs Clearance</Link>
                   <Link to="/Project">Project Cargo</Link>
                   <Link to="/Cargo">Cargo Lashing and Securing</Link>

                  </div>
                </li>
                
                <li className="nav-item mr-lg-1">
                  <Link className="nav-link" to="/Contact">
                    Contact
                  </Link>
                </li>
                {/*/search-right*/}
                <li className="header-search mr-lg-2 mt-lg-0 mt-4 position-relative">
                  <div className="search-right">
                    <Link
                      to="/Getquote"
                      className="btn btn-style search-btn"
                      title="search"
                    >
                     
                      Request A Quote
                    </Link>
                  </div>
                </li>
                {/*//search-right*/}
              </ul>
            </div>
           
          </nav>
        </div>
      </header>

      <Outlet />
    </>
  );
};

export default Header;
