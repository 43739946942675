import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb text-center">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container py-lg-5 py-md-4">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="title AboutPageBanner">Customs Clearance </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> Customs Clearance
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //bottom-grids*/}
      {/*/about-section */}
      <section className="w3l-homeblock1" id="about">
        <div className="midd-w3 py-5">
          <div className="container py-lg-5 py-md-3">
            <div className="row cwp23-grids align-items-center">
              <div className="col-lg-12">
                <h3 className="title-w3l text-center">
                Customs Clearance<span className="inn-text"></span>
                </h3>
                <h1 className="title-subw3hny text-center">_______</h1>
              </div>
              <div className="HomeAboutImages ">
                <div className="cwp23-text-cols p-4 ">
                  <div
                    className="column-w3-img position-relative text-center"
                    style={{ padding: "10px 120px" }}
                  >
                    <img
                      src="assets/images/customs.jpg"
                      alt=""
                      className="radius-image img-fluid"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <p className="mt-4">
                    Navigating the intricate realm of Customs Clearance is a
                    seamless process with {companyname}, encompassing a
                    comprehensive network of pivotal ports and strategic hubs.
                    Our adept team of professionals is committed to delivering
                    precise and punctual information, streamlining the customs
                    clearance journey.
                    <br />
                    With a wealth of experience, our dedicated staff diligently
                    oversee every facet, guaranteeing accuracy and efficiency at
                    every step. Our strong rapport with both our valued clients
                    and the South African Revenue Service (SARS) underscores our
                    commitment to fostering seamless collaborations and
                    adherence to regulatory mandates.
                    <br />
                    At {companyname}, we recognize that smooth customs clearance
                    is a vital link in the logistical chain, ensuring that your
                    shipments transcend borders with efficacy and compliance.
                    Our unwavering focus on accuracy, timely execution, and
                    robust communication underscores our dedication to
                    alleviating the complexities of customs clearance,
                    empowering you to navigate global trade with confidence.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
