import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb text-center">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container py-lg-5 py-md-4">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="title AboutPageBanner">Ground freight </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> Ground freight
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //bottom-grids*/}
      {/*/about-section */}
      <section className="w3l-homeblock1" id="about">
        <div className="midd-w3 py-5">
          <div className="container py-lg-5 py-md-3">
            <div className="row cwp23-grids align-items-center">
              <div className="col-lg-12">
                <h3 className="title-w3l text-center">
                Ground freight<span className="inn-text"></span>
                </h3>
                <h1 className="title-subw3hny text-center">_______</h1>
              </div>
              <div className="HomeAboutImages ">
                <div className="cwp23-text-cols p-4 ">
                  <div
                    className="column-w3-img position-relative text-center"
                    style={{ padding: "10px 120px" }}
                  >
                    <img
                      src="assets/images/ground.jpg"
                      alt=""
                      className="radius-image img-fluid"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <p className="mt-4">
                    Ground freight presents a cost-efficient avenue for
                    transporting substantial goods that don't demand immediate
                    delivery. This mode of freight shipping offers a practical
                    solution for large items that don't carry time-sensitive
                    constraints. With ground freight, you can anticipate an
                    average transit period ranging from three to 10 days,
                    contingent upon the specific points of pickup and delivery.
                    <br />
                    <br />
                    While ground freight boasts economical benefits, it's
                    essential to acknowledge its inherent time frame. If your
                    shipment necessitates swift, overnight, or high-speed
                    international delivery, it may warrant the exploration of
                    alternative transportation avenues. Ground freight shines
                    when time is on your side and when the focus lies on
                    budget-conscious, substantial item conveyance.
                    <br />
                    <br />
                    At {companyname}, we recognize that each shipment carries
                    its unique demands, and our commitment lies in tailoring
                    freight solutions that align precisely with your
                    requirements. Whether ground, air, or sea, our array of
                    freight services cater to diverse needs, ensuring that your
                    cargo travels securely and seamlessly, irrespective of the
                    distance or urgency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
