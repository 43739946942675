import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb text-center">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container py-lg-5 py-md-4">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="title AboutPageBanner">Cargo Lashing and Securing </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> Cargo Lashing and Securing
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //bottom-grids*/}
      {/*/about-section */}
      <section className="w3l-homeblock1" id="about">
        <div className="midd-w3 py-5">
          <div className="container py-lg-5 py-md-3">
            <div className="row cwp23-grids align-items-center">
              <div className="col-lg-12">
                <h3 className="title-w3l text-center">
                Cargo Lashing and Securing<span className="inn-text"></span>
                </h3>
                <h1 className="title-subw3hny text-center">_______</h1>
              </div>
              <div className="HomeAboutImages ">
                <div className="cwp23-text-cols p-4 ">
                  <div
                    className="column-w3-img position-relative text-center"
                    style={{ padding: "10px 120px" }}
                  >
                    <img
                      src="assets/images/cargo.png"
                      alt=""
                      className="radius-image img-fluid"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <p className="mt-4">
                    Cargo lashing is a crucial procedure aimed at enhancing the
                    safety and stability of transported goods during their
                    journey on a vessel. This meticulous process involves
                    strategically securing the cargo to prevent any undesired
                    shifting or movement while in transit. The significance of
                    cargo lashing becomes particularly pronounced when the load
                    is positioned on the deck of the vessel, exposing it to the
                    dynamic external forces generated by the ship's
                    multidirectional motion.
                    <br />
                    In the maritime realm, where the vessel navigates through
                    ever-changing waves and currents, effective cargo lashing
                    becomes paramount. Ropes, chains, cables, straps, nets, and
                    strapping serve as the guardians of the cargo's integrity,
                    working in tandem to anchor it firmly to the container.
                    These elements are meticulously tensioned, forming a
                    formidable barrier against the potentially disruptive forces
                    encountered during the voyage.
                    <br />
                    As the vessel maneuvers through the intricacies of its
                    maritime course, the steadfast cargo lashing ensures that
                    the consignment remains secure and steadfast, upholding the
                    principles of safe and efficient transportation. This
                    practice not only safeguards the cargo but also contributes
                    to the overall harmony and reliability of the maritime
                    shipping process.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
