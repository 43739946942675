import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb text-center">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container py-lg-5 py-md-4">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="title AboutPageBanner">About Us </h2>
                <p className="inner-page-para mt-2">Who We Are </p>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> About Us
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //bottom-grids*/}
      {/*/about-section */}
      <section className="w3l-homeblock1" id="about">
        <div className="midd-w3 py-5">
          <div className="container py-lg-5 py-md-3">
            <div className="row cwp23-grids align-items-center">
              <div className="col-lg-6">
                <h5 className="title-subw3hny">Who We Are</h5>
                <h3 className="title-w3l">
                  {companyname} <span className="inn-text"></span>
                </h3>
                <h6 className="">
                  Mastering Movement, Delivering with Precision.
                </h6>
                <p className="mt-4">
                  Amid the dynamic and intricate landscape of modern supply
                  chain logistics, the art of managing risk has emerged as a
                  pivotal factor in determining the triumph of businesses. Now
                  more than ever, the ability to deftly navigate uncertainties
                  can be the key difference between success and stagnation.
                  <br />
                  In the face of these challenges, the selection of a
                  trustworthy and adept freight forwarder becomes a strategic
                  imperative. Such a partner serves as a guiding beacon,
                  steering your business ahead in this ever-evolving and
                  uncertain terrain. {companyname} stands as a beacon of
                  reliability in this regard, boasting a legacy marked by
                  resounding customer satisfaction and an unwavering commitment
                  to dedicated service.
                  <br />
                </p>
              </div>
              <div className="HomeAboutImages col-lg-6 mt-lg-0 mt-5 pl-lg-5">
                <div className="cwp23-text-cols row">
                  <div className="column col-6">
                    <div className="column-w3-img position-relative">
                      <Link>
                        <img
                          src="assets/images/1.jpg"
                          alt=""
                          className="radius-image img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="column-w3-img position-relative mt-4">
                      <Link>
                        <img
                          src="assets/images/2.jpg"
                          alt=""
                          className="radius-image img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="column col-6">
                    <div className="column-w3-img position-relative">
                      <Link>
                        <img
                          src="assets/images/3.jpg"
                          alt=""
                          className="radius-image img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="column-w3-img position-relative mt-4">
                      <Link>
                        <img
                          src="assets/images/4.jpg"
                          alt=""
                          className="radius-image img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="mt-4">
              What sets {companyname} apart is not just its impressive track
              record, but its inherent ethos of excellence. As a proudly
              Indian-owned entity, {companyname} has spread its wings to
              encompass a global network, connecting businesses across borders.
              Our international partners share our core value of
              client-centricity, ensuring a seamless and consistent experience
              regardless of geographical boundaries.
              <br />
              Behind our success stands a team of industry virtuosos who embody
              a commitment to excellence. It is through their dedication,
              expertise, and relentless pursuit of perfection that {
                companyname
              }{" "}
              has cemented its position as a leader in the freight forwarding
              realm. We don't merely offer services; we provide peace of mind,
              knowing that your cargo is in the safest and most capable hands.
              In an ever-shifting landscape, {companyname} remains steadfast,
              your trusted ally in the complex world of supply chain logistics.
            </p>
          </div>
        </div>
      </section>
      <section className="w3l-bottom-grids-6 py-5" id="grids">
        <div className="container py-md-5 py-2">
          <div className="grids-area-hny row text-left pt-lg-5 mt-lg-5">
            <div className="col-lg-4 col-md-6 grids-feature pr-lg-5">
              <div className="area-box">
                <span className="fas fa-eye" />
                <h4>
                  <Link className="title-head">Our Vision</Link>
                </h4>
                <p>
                  Fostering Strong Customer Connections and Ensuring Long-Term
                  Contract Logistics Success for {companyname}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 grids-feature mt-md-0 mt-5">
              <div className="area-box">
                <span className="fas fa-bullseye" />
                <h4>
                  <Link className="title-head">Our Mission</Link>
                </h4>
                <p>
                  Shifting Customer Mindset, Delivering More Than Services –
                  Offering Peace of Mind with {companyname}'s Safe and Secure
                  Hands.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 grids-feature mt-lg-0 mt-5 pl-lg-5">
              <div className="area-box">
                <span className="fas fa-check" />
                <h4>
                  <Link className="title-head">Objective</Link>
                </h4>
                <p>
                  Emerging as the Preferred Service Provider in Our Industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
