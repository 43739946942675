import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      <section className="bannerw3l-hnyv">
        <div className="banner-layer">
          <video autoPlay muted loop id="myVideo">
            <source src="assets/images/video.mp4" type="video/mp4" />
          </video>
          <div className="main-content-top">
            <div className="container">
              <div className="main-content">
                <h4>We Are {companyname}</h4>
                <h3>
                  Your Cargo, Our Promise -
                  <span className="inn-text">Reliable Freight Forwarding.</span>{" "}
                </h3>
                <Link
                  to="/About"
                  className="btn btn-style transparant-btn mt-md-5 mt-4"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
       
      </section>
      {/*//Banner-End*/}
      {/* /bottom-grids*/}
      <section className="w3l-bottom-grids-6 py-5" id="grids">
        <div className="container py-md-5 py-2">
          <div className="grids-area-hny row text-left pt-lg-5 mt-lg-5">
            <div className="col-lg-4 col-md-6 grids-feature pr-lg-5">
              <div className="area-box">
                <span className="fas fa-eye" />
                <h4>
                  <Link className="title-head">Our Vision</Link>
                </h4>
                <p>
                  Fostering Strong Customer Connections and Ensuring Long-Term
                  Contract Logistics Success for {companyname}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 grids-feature mt-md-0 mt-5">
              <div className="area-box">
                <span className="fas fa-bullseye" />
                <h4>
                  <Link className="title-head">Our Mission</Link>
                </h4>
                <p>
                  Shifting Customer Mindset, Delivering More Than Services –
                  Offering Peace of Mind with {companyname}'s Safe and Secure
                  Hands.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 grids-feature mt-lg-0 mt-5 pl-lg-5">
              <div className="area-box">
                <span className="fas fa-check" />
                <h4>
                  <Link className="title-head">Objective</Link>
                </h4>
                <p>
                  Emerging as the Preferred Service Provider in Our Industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //bottom-grids*/}
      {/*/about-section */}
      <section className="w3l-homeblock1" id="about">
        <div className="midd-w3 py-5">
          <div className="container py-lg-5 py-md-3">
            <div className="row cwp23-grids align-items-center">
              <div className="col-lg-6">
                <h5 className="title-subw3hny">Who We Are</h5>
                <h3 className="title-w3l">
                  {companyname} <span className="inn-text"></span>
                </h3>
                <h6 className="">
                  Mastering Movement, Delivering with Precision.
                </h6>
                <p className="mt-4">
                  Amid the dynamic and intricate landscape of modern supply
                  chain logistics, the art of managing risk has emerged as a
                  pivotal factor in determining the triumph of businesses. Now
                  more than ever, the ability to deftly navigate uncertainties
                  can be the key difference between success and stagnation. In
                  the face of these challenges, the selection of a trustworthy
                  and adept freight forwarder becomes a strategic imperative.
                  Such a partner serves as a guiding beacon, steering your
                  business ahead in this ever-evolving and uncertain terrain.{" "}
                  {companyname} stands as a beacon of reliability in this
                  regard, boasting a legacy marked by resounding customer
                  satisfaction and an unwavering commitment to dedicated
                  service.
                </p>
                <Link
                  to="/About"
                  className="btn btn-style btn-primary mt-lg-5 mt-4"
                >
                  Learn More
                </Link>
              </div>
              <div className="HomeAboutImages col-lg-6 mt-lg-0 mt-5 pl-lg-5">
                <div className="cwp23-text-cols row">
                  <div className="column col-6">
                    <div className="column-w3-img position-relative">
                      <Link>
                        <img
                          src="assets/images/1.jpg"
                          alt=""
                          className="radius-image img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="column-w3-img position-relative mt-4">
                      <Link>
                        <img
                          src="assets/images/2.jpg"
                          alt=""
                          className="radius-image img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="column col-6">
                    <div className="column-w3-img position-relative">
                      <Link>
                        <img
                          src="assets/images/3.jpg"
                          alt=""
                          className="radius-image img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="column-w3-img position-relative mt-4">
                      <Link>
                        <img
                          src="assets/images/4.jpg"
                          alt=""
                          className="radius-image img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*//about-section */}
      {/*/home-stats*/}
      
      <section className="w3l-courses" id="courses">
        <div className="blog py-5">
          <div className="container py-md-5 py-2">
            <h5 className="title-subw3hny text-center">What We Do..</h5>
            <h3 className="title-w3l text-center">
              Our <span className="inn-text">Services</span>
            </h3>
            <div className="row">
              <div className="col-lg-4 col-md-6 item mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Ocean" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"  style={{height:'220px'}}
                        src="assets/images/ocean.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="post-pos">
                      <Link to="/Services" className="receipe blue">
                      Services
                      </Link>
                    
                    </div>
                  </div>
                  <div className="card-body course-details">
                    
                    <Link to="/Ocean" className="course-desc text-uppercase">
                      Ocean Freight
                    </Link>
                   
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Air" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"  style={{height:'220px'}}
                        src="assets/images/air.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="post-pos">
                      <Link to="/Services" className="receipe blue">
                      Services
                      </Link>
                    
                    </div>
                  </div>
                  <div className="card-body course-details">
                    
                    <Link to="/Air" className="course-desc text-uppercase">
                    Air Freight
                    </Link>
                   
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Ground" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"  style={{height:'220px'}}
                        src="assets/images/ground.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="post-pos">
                      <Link to="/Services" className="receipe blue">
                      Services
                      </Link>
                    
                    </div>
                  </div>
                  <div className="card-body course-details">
                    
                    <Link to="/Ground" className="course-desc text-uppercase">
                    Ground Freight
                    </Link>
                   
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Customs" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"  style={{height:'220px'}}
                        src="assets/images/customs.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="post-pos">
                      <Link to="/Services" className="receipe blue">
                      Services
                      </Link>
                    
                    </div>
                  </div>
                  <div className="card-body course-details">
                    
                    <Link to="/Customs" className="course-desc text-uppercase">
                    Customs Clearance
                    </Link>
                   
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Project" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"  style={{height:'220px'}}
                        src="assets/images/project.jpeg"
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="post-pos">
                      <Link to="/Services" className="receipe blue">
                      Services
                      </Link>
                    
                    </div>
                  </div>
                  <div className="card-body course-details">
                    
                    <Link to="/Project" className="course-desc text-uppercase">
                    Project Cargo
                    </Link>
                   
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Cargo" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"  style={{height:'220px'}}
                        src="assets/images/cargo.png"
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="post-pos">
                      <Link to="/Services" className="receipe blue">
                      Services
                      </Link>
                    
                    </div>
                  </div>
                  <div className="card-body course-details">
                    
                    <Link to="/Cargo" className="course-desc text-uppercase">
                    Cargo Lashing and Securing
                    </Link>
                   
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
     

      <Footer />
    </>
  );
}
