import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb text-center">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container py-lg-5 py-md-4">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="title AboutPageBanner">Ocean Freight </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" />  Ocean Freight
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //bottom-grids*/}
      {/*/about-section */}
      <section className="w3l-homeblock1" id="about">
        <div className="midd-w3 py-5">
          <div className="container py-lg-5 py-md-3">
            <div className="row cwp23-grids align-items-center">
              <div className="col-lg-12">
                <h3 className="title-w3l text-center">
                 Ocean Freight<span className="inn-text"></span>
                </h3>
                <h1 className="title-subw3hny text-center">_______</h1>
              </div>
              <div className="HomeAboutImages ">
                <div className="cwp23-text-cols p-4 ">
                  
                    <div className="column-w3-img position-relative text-center" style={{padding:"10px 120px"}}>
                        <img
                          src="assets/images/ocean.jpg"
                          alt=""
                          className="radius-image img-fluid"
                          style={{width:"100%"}}
                        />
                    </div>
                    <p className="mt-4">
                    When time is not a pressing factor for your shipment, embracing ocean freight emerges as a highly cost-effective alternative. At {companyname}, we have forged robust partnerships with leading maritime entities, ensuring that your cargo traverses the global waters with optimal efficiency and effectiveness.<br/><br/>

Our Ocean Freight Services cater to a spectrum of needs, embodying a commitment to comprehensive solutions. From seamless Ocean Freight Door-to-Door operations that encapsulate the entire journey, to the versatile realm of Vessel Chartering, we orchestrate a diverse range of offerings to suit your distinct requirements.<br/><br/>

Be it Breakbulk Shipment and Chartering by Sea, a niche expertise that demands meticulous coordination, or the skillful handling of Bulk Cargo, our proficiency extends to ensuring that your goods traverse the ocean expanse with utmost care and precision.<br/><br/>

Additionally, our specialized Roll-On/Roll-Off (RORO) Services empower you with a solution tailored for vehicles and equipment, ensuring smooth transitions from port to port, continent to continent.<br/><br/>

At {companyname}, we don't just ship cargo; we facilitate seamless global connections through our Ocean Freight Services, underpinned by a legacy of reliability, excellence, and strategic partnerships that span across sea and shore.
            </p>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
