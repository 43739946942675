import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />

      <section className="w3l-about-breadcrumb text-center">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container py-lg-5 py-md-4">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="title AboutPageBanner">What We Do.. </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> What We
                    Do..
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-courses" id="courses">
        <div className="blog py-5">
          <div className="container py-md-5 py-2">
            <h5 className="title-subw3hny text-center">What We Do..</h5>
            <h3 className="title-w3l text-center">
              Our <span className="inn-text">Services</span>
            </h3>
            <div className="row">
              <div className="col-lg-4 col-md-6 item mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Ocean" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        style={{ height: "220px" }}
                        src="assets/images/ocean.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="post-pos">
                      <Link to="/Services" className="receipe blue">
                        Services
                      </Link>
                    </div>
                  </div>
                  <div className="card-body course-details">
                    <Link to="/Ocean" className="course-desc text-uppercase">
                      Ocean Freight
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Air" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        style={{ height: "220px" }}
                        src="assets/images/air.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="post-pos">
                      <Link to="/Services" className="receipe blue">
                        Services
                      </Link>
                    </div>
                  </div>
                  <div className="card-body course-details">
                    <Link to="/Air" className="course-desc text-uppercase">
                      Air Freight
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Ground" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        style={{ height: "220px" }}
                        src="assets/images/ground.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="post-pos">
                      <Link to="/Services" className="receipe blue">
                        Services
                      </Link>
                    </div>
                  </div>
                  <div className="card-body course-details">
                    <Link to="/Ground" className="course-desc text-uppercase">
                      Ground Freight
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Customs" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        style={{ height: "220px" }}
                        src="assets/images/customs.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="post-pos">
                      <Link to="/Services" className="receipe blue">
                        Services
                      </Link>
                    </div>
                  </div>
                  <div className="card-body course-details">
                    <Link to="/Customs" className="course-desc text-uppercase">
                      Customs Clearance
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Project" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        style={{ height: "220px" }}
                        src="assets/images/project.jpeg"
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="post-pos">
                      <Link to="/Services" className="receipe blue">
                        Services
                      </Link>
                    </div>
                  </div>
                  <div className="card-body course-details">
                    <Link to="/Project" className="course-desc text-uppercase">
                      Project Cargo
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Cargo" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        style={{ height: "220px" }}
                        src="assets/images/cargo.png"
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="post-pos">
                      <Link to="/Services" className="receipe blue">
                        Services
                      </Link>
                    </div>
                  </div>
                  <div className="card-body course-details">
                    <Link to="/Cargo" className="course-desc text-uppercase">
                      Cargo Lashing and Securing
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
