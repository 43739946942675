import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb text-center">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container py-lg-5 py-md-4">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="title AboutPageBanner"> Project Cargo </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> Project Cargo
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //bottom-grids*/}
      {/*/about-section */}
      <section className="w3l-homeblock1" id="about">
        <div className="midd-w3 py-5">
          <div className="container py-lg-5 py-md-3">
            <div className="row cwp23-grids align-items-center">
              <div className="col-lg-12">
                <h3 className="title-w3l text-center">
                Project Cargo<span className="inn-text"></span>
                </h3>
                <h1 className="title-subw3hny text-center">_______</h1>
              </div>
              <div className="HomeAboutImages ">
                <div className="cwp23-text-cols p-4 ">
                  <div
                    className="column-w3-img position-relative text-center"
                    style={{ padding: "10px 120px" }}
                  >
                    <img
                      src="assets/images/project.jpeg"
                      alt=""
                      className="radius-image img-fluid"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <p className="mt-4">
                    At {companyname}, our intricate logistics ecosystem,
                    bolstered by a synergy of cutting-edge processes, top-tier
                    services, advanced tools, and seamless systems, harmonizes
                    with strategically positioned warehouses to empower our
                    clients in meeting their diverse business logistic
                    requisites. This synergy culminates in a precision-driven
                    approach that not only fulfills immediate logistics needs
                    but also paves the path toward achieving enduring long-term
                    business aspirations.
                    <br />
                    Our adept supply chain management experts are poised to
                    craft bespoke logistic frameworks meticulously tailored to
                    your unique business DNA, seamlessly integrating with your
                    operations. Whether you seek a one-time delivery solution or
                    aspire for a steadfast and professional trucking service to
                    cater to your ongoing transportation demands, rest assured,
                    our commitment to excellence shines through.
                    <br />
                    We take pride in presenting a spectrum of logistics
                    solutions that seamlessly adapt to your requirements while
                    maintaining an economically prudent approach. Our dedicated
                    logistics consultants stand ready to address any intricacies
                    and offer comprehensive insights into our trucking services,
                    ensuring that your logistical journey with ABDUi is marked
                    by efficiency, reliability, and unwavering support.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
