import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb text-center">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container py-lg-5 py-md-4">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="title AboutPageBanner">Air Freight </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> Air
                    Freight
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //bottom-grids*/}
      {/*/about-section */}
      <section className="w3l-homeblock1" id="about">
        <div className="midd-w3 py-5">
          <div className="container py-lg-5 py-md-3">
            <div className="row cwp23-grids align-items-center">
              <div className="col-lg-12">
                <h3 className="title-w3l text-center">
                  Air Freight<span className="inn-text"></span>
                </h3>
                <h1 className="title-subw3hny text-center">_______</h1>
              </div>
              <div className="HomeAboutImages ">
                <div className="cwp23-text-cols p-4 ">
                  <div
                    className="column-w3-img position-relative text-center"
                    style={{ padding: "10px 120px" }}
                  >
                    <img
                      src="assets/images/air.jpg"
                      alt=""
                      className="radius-image img-fluid"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <p className="mt-4">
                    Rest assured, our commitment to excellence ensures that you
                    receive not only the most competitive pricing but also an
                    unparalleled level of service, ensuring your air freight
                    cargo arrives precisely where it needs to be, precisely when
                    it should be there – all in a secure and economically
                    efficient manner.
                    <br />
                    <br />
                    Our comprehensive suite of Air Freight Services encompasses
                    every aspect of your cargo's journey. Whether it's
                    facilitating seamless Air Freight Export and Import
                    operations, orchestrating efficient Door-to-Door Deliveries,
                    or deftly managing the complexities of Oversized Dimensional
                    Cargo through air channels, we have you covered.
                    <br />
                    <br />
                    Moreover, we understand that urgency is often the driving
                    force behind air freight shipments. That's why our service
                    extends to fulfill the most urgent of freight requirements,
                    ensuring that even the most time-sensitive cargo reaches its
                    destination promptly and efficiently.
                    <br />
                    <br />
                    At every step, our aim is not just to meet expectations but
                    to exceed them. With our unwavering dedication, your air
                    freight cargo is not just a package in transit – it's a
                    commitment to delivering excellence, security, and
                    timeliness, every single time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
