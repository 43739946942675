import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <section className="w3l-about-breadcrumb text-center">
          <div className="breadcrumb-bg breadcrumb-bg-about py-5">
            <div className="container py-lg-5 py-md-4">
              <div className="w3breadcrumb-gids">
                <div className="w3breadcrumb-left text-left">
                  <h2 className="title AboutPageBanner">
                    Contact Us </h2>
                  <p className="inner-page-para mt-2">
                  Unlock seamless global shipping solutions by filling up our contact form today. </p>
                </div>
                <div className="w3breadcrumb-right">
                  <ul className="breadcrumbs-custom-path">
                    <li><Link to="/Home">Home</Link></li>
                    <li className="active"><span className="fas fa-angle-double-right mx-2" /> Contact</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*//breadcrumb*/}
        {/* contacts-5-grid */}
        <div className="w3l-contact-10 py-5" id="contact">
          <div className="form-41-mian pt-lg-4 pt-md-3 pb-lg-4">
            <div className="container">
              <div className="heading text-center mx-auto">
                <h5 className="title-subw3hny text-center">Contact Us</h5>
                <h3 className="title-w3l">Have any <span className="inn-text">Query? </span></h3>
              </div>
              <div className="contacts-5-grid-main mt-5">
                <div className="contacts-5-grid">
                  <div className="map-content-5">
                    <div className="d-grid grid-col-2">
                      <div className="contact-type">
                        <div className="address-grid">
                          <h6><span className="fas fa-map-marked-alt" /> Address</h6>
                          <p>{companyaddress}</p>
                        </div>
                        <div className="address-grid">
                          <h6><span className="fas fa-envelope-open-text" /> Email</h6>
                          <Link to="mailto:{companyemail}" className="link1">{companyemail}</Link>
                        </div>
                        <div className="address-grid">
                          <h6><span className="fas fa-phone-alt" /> Phone</h6>
                          <Link to="tel:{companynumber}" className="link1">{companynumber}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-inner-cont mt-5">
              <form
                    action="/php/thankyou-contact.php "
                    method="post"
                    onSubmit={(event) => handleSubmit(event)}
                  >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                      <input
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        className
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                      />
                      <span />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                      <input
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                      />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                      <input
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                      />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                      <input
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                      />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                      </div>
                    </div>
                    <div className="col-12">
                      <button name="submit" className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
              <h1>{result}</h1>
              </div>
            </div>
          </div>
          {/* //contacts-5-grid */}
        </div>
        <div className="contacts-sub-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60275.69726106052!2d72.9344066!3d19.2287584!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b92abc14627f%3A0x67321c4ebf19795e!2sLake%20City%20Mall!5e0!3m2!1sen!2sin!4v1690354023087!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe> </div>


      <Footer />
    </>
  );
}
