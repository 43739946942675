import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
       <footer className="w3l-footer-22 position-relative">
          <div className="footer-sub py-5">
            <div className="container py-md-5">
              <div className="row sub-columns">
                <div className="col-lg-4 col-md-6 sub-one-left ab-right-cont pr-lg-5 mb-md-0  mb-4">
                  <h6>About </h6>
                  <p>What sets Resaplanet International apart is not just its impressive track record, but its inherent ethos of excellence. As a proudly Indian-owned entity, Resaplanet International has spread its wings to encompass a global network, connecting businesses across borders.</p>
                  
                </div>
                <div className="col-lg-2 col-md-6 sub-two-right pl-lg-5">
                  <h6>Useful Links</h6>
                  <ul>
                    <li><Link to="/Home"><span className="fas fa-chevron-right mr-2" />Home</Link>
                    </li>
                    <li><Link to="/About"><span className="fas fa-chevron-right mr-2" />About</Link>
                    </li>
                    <li><Link to="/Services"><span className="fas fa-chevron-right mr-2" />Services</Link></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 sub-two-right pl-lg-5 mt-md-0 mt-4">
                  <h6>Services</h6>
                  <ul>
                  <li> <Link to="/Ocean"><span className="fas fa-chevron-right mr-2" />Ocean Freight</Link></li>
                  <li><Link to="/Air"><span className="fas fa-chevron-right mr-2" />Air Freight</Link></li>
                  <li><Link to="/Ground"><span className="fas fa-chevron-right mr-2" />Ground Freight</Link></li>
                  <li><Link to="/Customs"><span className="fas fa-chevron-right mr-2" />Customs Clearance</Link></li>
                  <li> <Link to="/Project"><span className="fas fa-chevron-right mr-2" />Project Cargo</Link></li>
                  <li><Link to="/Cargo"><span className="fas fa-chevron-right mr-2" />Cargo Lashing and Securing</Link></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 sub-two-right mt-md-0 mt-4">
                  <h6>Help & Support</h6>
                  <ul>
                  <li><Link to="/Contact"><span className="fas fa-chevron-right mr-2" />Home</Link>
                    </li>
                    <li><Link to="/Getquote"><span className="fas fa-chevron-right mr-2" />Getquote</Link>
                    </li>
                    <li><Link to="/Privacy"><span className="fas fa-chevron-right mr-2" />Privacy Policy</Link></li>
                    <li><Link to="/Terms"><span className="fas fa-chevron-right mr-2" />Terms</Link></li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* copyright */}
          <div className="copyright-footer text-center">
            <div className="container">
              <div className="columns">
                <p>© 2023 {companyname}. All rights reserved.
                </p>
              </div>
            </div>
          </div>
          {/* //copyright */}
        </footer>
        {/* //footer */}
        {/* Js scripts */}
        {/* move top */}
        <button onclick="topFunction()" id="movetop" title="Go to top">
          <span className="fas fa-level-up-alt" aria-hidden="true" />
        </button>
    </>
  );
};

export default Footer;
